import React from 'react';
import { Box } from '@chakra-ui/react';
import { graphql, HeadProps } from 'gatsby';
import { ArticleTemplateQuery } from '../../../gatsby-graphql';
import Layout from '../../components/shared/Layout';
import { SEO, H1, ResponsiveFlex } from 'components';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
interface Props {
  data: ArticleTemplateQuery;
}

const ArticleTemplate = ({ data: { mdx: article } }: Props) => {
  if (!(article && article.frontmatter)) {
    return <p>error</p>;
  }
  console.log({ article });
  const {
    title,
    category,
    createdAt,
    slug,
    featuredImage,
    embeddedImagesLocal,
  } = article.frontmatter;
  const { excerpt, body } = article;

  const featuredImageData = getImage(featuredImage as ImageDataLike);

  return (
    <Layout>
      <Box as={'article'}>
        <ResponsiveFlex as="section" textAlign={'center'} gap={10} px={0}>
          <H1 variant={'h1-article'}>{title}</H1>
          {/* <MainImage mainImage={mainImage} /> */}
          {featuredImageData && (
            <GatsbyImage alt="" image={featuredImageData} />
          )}
        </ResponsiveFlex>
        {/* <TableOfContents body={body} slug={slug} />*/}
        <Box p={10} py={0} maxW={['full', '80%']} margin="auto">
          <MDXRenderer localImages={embeddedImagesLocal}>{body}</MDXRenderer>
        </Box>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query ArticleTemplate($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        createdAt
        updatedAt
        title
        category
        slug
        featuredImage {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
      tableOfContents
      timeToRead
      excerpt
    }
  }
`;

export const Head = ({
  location: { pathname },
  data: { mdx },
}: HeadProps<ArticleTemplateQuery>) => {
  if (!mdx) {
    return null;
  }
  const { frontmatter, excerpt } = mdx;
  console.log({ pathname });

  return (
    <SEO
      article={true}
      createdAt={frontmatter?.createdAt}
      pathName={pathname}
      title={frontmatter?.title}
      description={excerpt}
      updatedAt={frontmatter?.updatedAt}
    />
  );
};

export default ArticleTemplate;
